import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91slug_939BhwXGwnBKMeta } from "/app/pages/blog/[section]/[slug].vue?macro=true";
import { default as indexIsQ8vQihJKMeta } from "/app/pages/blog/[section]/index.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91id_93y9w7wnh1syMeta } from "/app/pages/cart/[id].vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as _91id_93qGb7sUHr7XMeta } from "/app/pages/checkout/[id].vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as _91id_93AE9MRUqPLBMeta } from "/app/pages/checkout/success/[id].vue?macro=true";
import { default as favoritesYMtsvzhBvNMeta } from "/app/pages/favorites.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as ordersFd8PY4blJCMeta } from "/app/pages/orders.vue?macro=true";
import { default as _91slug_93FPU7I0zot8Meta } from "/app/pages/product/[slug].vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as _91token_93K7acn9QelAMeta } from "/app/pages/recover-password/[token].vue?macro=true";
import { default as request_45an_45appointmentFJqR2UUUdoMeta } from "/app/pages/request-an-appointment.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as sign_45inMH4whhaZtqMeta } from "/app/pages/sign-in.vue?macro=true";
import { default as sign_45upacRUXSamu0Meta } from "/app/pages/sign-up.vue?macro=true";
import { default as track_45orderv0hHwuF2jbMeta } from "/app/pages/track-order.vue?macro=true";
import { default as _91token_935yfqHd94b8Meta } from "/app/pages/verify/[token].vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "blog-section-slug___en",
    path: "/blog/:section()/:slug()",
    component: () => import("/app/pages/blog/[section]/[slug].vue")
  },
  {
    name: "blog-section___en",
    path: "/blog/:section()",
    meta: indexIsQ8vQihJKMeta || {},
    component: () => import("/app/pages/blog/[section]/index.vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "cart-id___en",
    path: "/cart/:id()",
    component: () => import("/app/pages/cart/[id].vue")
  },
  {
    name: "cart___en",
    path: "/cart",
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "checkout-id___en",
    path: "/checkout/:id()",
    component: () => import("/app/pages/checkout/[id].vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout-success-id___en",
    path: "/checkout/success/:id()",
    component: () => import("/app/pages/checkout/success/[id].vue")
  },
  {
    name: "favorites___en",
    path: "/favorites",
    meta: favoritesYMtsvzhBvNMeta || {},
    component: () => import("/app/pages/favorites.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "orders___en",
    path: "/orders",
    component: () => import("/app/pages/orders.vue")
  },
  {
    name: "product-slug___en",
    path: "/product/:slug()",
    component: () => import("/app/pages/product/[slug].vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "recover-password-token___en",
    path: "/recover-password/:token()",
    meta: _91token_93K7acn9QelAMeta || {},
    component: () => import("/app/pages/recover-password/[token].vue")
  },
  {
    name: "request-an-appointment___en",
    path: "/request-an-appointment",
    component: () => import("/app/pages/request-an-appointment.vue")
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue")
  },
  {
    name: "sign-in___en",
    path: "/sign-in",
    meta: sign_45inMH4whhaZtqMeta || {},
    component: () => import("/app/pages/sign-in.vue")
  },
  {
    name: "sign-up___en",
    path: "/sign-up",
    meta: sign_45upacRUXSamu0Meta || {},
    component: () => import("/app/pages/sign-up.vue")
  },
  {
    name: "track-order___en",
    path: "/track-order",
    meta: track_45orderv0hHwuF2jbMeta || {},
    component: () => import("/app/pages/track-order.vue")
  },
  {
    name: "verify-token___en",
    path: "/verify/:token()",
    component: () => import("/app/pages/verify/[token].vue")
  }
]